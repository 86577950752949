// require('jquery-ui');
require('../../node_modules/jquery-ui-dist/jquery-ui.js');
require('../../node_modules/jquery-ui-dist/jquery-ui.css');

window.fluidDialog = function() {
    let visible = $(".ui-dialog:visible");
    // each open dialog
    visible.each(function () {
        let $this = $(this);
        let dialog = $this.find(".ui-dialog-content").data("ui-dialog");
        // if fluid option == true
        if (dialog.options.fluid) {
            let wWidth = $(window).width();
            let wHeight = $(window).height();
            // check window width against dialog width
            if (wWidth < (parseInt(dialog.options.maxWidth) + 50)) {
                // keep dialog from filling entire screen
                dialog.option("maxWidth", '100%');
            }
            //reposition dialog
            if (wHeight < (parseInt($this.height()) + 50)) {
                $this.css('overflow', 'auto');
            }
            else {
                $this.css('overflow', 'initial');
            }
            dialog.option("position", dialog.options.position);
        }
    });
};

window.showDialog = function(url, width, successCallback) {
    $('#dialog .overlay.loader').addClass('visible');
    if (width === '100%') {
        width = window.innerWidth;
    }
    $.ajax({
        url: url,
        success: function (data) {
            renderDialog(data, width, successCallback);
        }
    });
};

window.renderDialog = function (content, width, successCallback) {
    let dialog = $("#dialog");
    dialog.html(content);
    dialog.dialog({
        dialogClass: 'no-titlebar',
        modal: true,
        width: 'auto',
        maxWidth: 950,
        height: 'auto',
        maxHeight: '90%',
        draggable: false,
        resizable: false,
        fluid: true,
        show: {
            effect: 'fadeIn',
            duration: 200
        },
        hide: {
            effect: 'fadeOut',
            duration: 200
        },
        buttons: [
            {
                class: 'button button-invert button-square button-close dialog-close',
                click: function () {
                    $(this).dialog("close");
                }
            }
        ],
        open: function (event, ui) {   //added here
            $(this).parent().attr('aria-describedby', null);
            $(".ui-widget-overlay").click(function () {
                $('#dialog').dialog('close');
            });
            $('button.button-close').focus();
            fluidDialog();
            $(this).dialog('widget').position({my: "center", at: "center", of: window});
            $('select.nice-select').each(function () {
                InitNiceSelectField($(this));
            });
            // $('select.select2').each(function() {
            //     InitSelect2Field($(this));
            // });
            // $('.select2entity[data-autostart="true"]').each(function() {
            //     $(this).select2entity();
            //     SetSelect2EntityPlaceholder($(this));
            // });
            $('form.ajax-form').on('submit', function () {
                window.setTimeout(function () {
                    $('#dialog').dialog('widget').position({my: "center", at: "center", of: window});
                }, 100);
            });
        },
    });
    $('#dialog .overlay.loader').removeClass('visible');
    if (successCallback) {
        successCallback();
    }
};

$(window).on('resize', function() {
    fluidDialog();
});

$(function () {

    // Popup Btn
    $(document).on('click', '.button-popup', function (e) {
        let a = $(this);
        let link = '';
        if (a.hasClass('o-link')) {
            link = a.data('o');
            link = atob(link);
        }
        else {
            link = a.attr('href');
        }
        showDialog(link, a.data('width'), a.data('success-callback'));
        e.preventDefault();
    });

    // Popup close btn
    $(document).on('click', '.popup-close-button', function (e) {
        $('#dialog').dialog('close');
    });

});