require('./base.meganav');

function toggleNav() {

    let ww = $(window).width();
    let wh = $(window).height();

    if ($('#nav-button .hamburger').hasClass('is-active')) {
        $('#nav-button .hamburger').attr('aria-label', $('#nav-button .hamburger').data('aria-label-close'));
        $('#nav-button .hamburger span.sr-only').html($('#nav-button .hamburger').data('aria-label-close'));
        $('#nav-button .hamburger').attr('aria-expanded', true);
        $('header').addClass('nav-deployed');
        $('body').addClass('nav-deployed');
        $('#nav-content').addClass('deploy').attr('aria-hidden', false);
        // $("#nav-content").fadeTo(500, 1);
        $('body').css('width', ww);
        if (($('body').height()) > wh) {
            $('body').css('overflow-y', 'scroll');
        }
        $('#nav-button .hamburger').focus();
        // $('main').addClass('hidden');
        // $('footer').addClass('hidden');
    }
    else {
        $('#nav-button .hamburger').attr('aria-label', $('#nav-button .hamburger').data('aria-label-open'));
        $('#nav-button .hamburger span.sr-only').html($('#nav-button .hamburger').data('aria-label-open'));
        $('#nav-button .hamburger').attr('aria-expanded', false);
        $('nav.header-menu > ul').removeClass('hide');
        $('.mega-nav').removeClass('visible');
        $('.mega-menu').removeClass('hide');
        $('#nav-content').removeClass('deploy').attr('aria-hidden', true);
        $('header').removeClass('nav-deployed').removeClass('expanded');
        $('body').removeClass('nav-deployed');
        $('body').css('width', 'auto');
        if (($('body').height()) > wh) {
            $('body').css('overflow-y', 'auto');
        }
        $('#nav-button .hamburger').focus();
        // $('main').removeClass('hidden');
        // $('footer').removeClass('hidden');
    }

    window.handleScroll();
}

$(function () {

    $('.hamburger').click(function () {
        $(this).toggleClass('is-active');
        toggleNav();
    });

});