let $ = require('jquery');

let debugMode = false;
let displayPopup = false;
let cookieName = 'pure-rgpd-kookki';
let cookieDuration = 365 * 24 * 3600;       // en secondes
let cookieAnchor = 'rgpd';
let defaultConsents;
let consents = {};
let temporaryConsents = {};
let callbacks = {};
let box = null;
let popup = null;
let autoSave = true;


const consentCookiesNames = {       // Cookie names for each service
    'stats-analytics': ['_ga', '_gat', '_gid', '__utma', '__utmb', '__utmc', '__utmt', '__utmz', '_gat_UA-*', '_gat_gtag_UA_*'],
    'stats-maps': ['nid', 'apisid', 'hsid', 'nid', 'sapisid', 'sid', 'sidcc', 'ssid', '1p_jar'],
    'ads-facebook': ['datr', 'fr', 'reg_ext_ref', 'reg_fb_gate', 'reg_fb_ref', 'sb', 'wd', 'x-src'],
    'video-youtube': ['VISITOR_INFO1_LIVE', 'YSC', 'PREF', 'GEUP'],
};

class Rgpd {

    static init(_debugMode, _displayPopup, _cookieName, _cookieAnchor, _defaultConsents) {

        debugMode = _debugMode;
        displayPopup = _displayPopup;
        cookieName = _cookieName;
        cookieAnchor = _cookieAnchor;
        defaultConsents = _defaultConsents;

        if(debugMode) {
            console.log('Rgpd : init');
        }

        Rgpd.loadConsent();
        Rgpd.runCallbacks(); // au cas ou un script a fait une demande avant cet init

        if(debugMode) {
            console.log('Rgpd : cookie loaded ', consents);
        }

        Rgpd.registerEventListeners();

        displayPopup = false;
        for(let authorizationCategory in consents) {
            if(typeof consents[authorizationCategory] === 'object') {
                for(let authorizationService in consents[authorizationCategory]) {
                    if(consents[authorizationCategory][authorizationService] === null) {
                        displayPopup = true;
                    }
                }
            } else {
                if(consents[authorizationCategory] === null) {
                    displayPopup = true;
                }
            }
        }

        if(displayPopup) {
            Rgpd.showBox();
        }
    }

    static test(authorization = null, callbackIfTrue = null, callbackIfFalse = null) {

        let authorized = false;
        if (authorization != null) {
            if (consents.hasOwnProperty(authorization)) {
                authorized = consents[authorization] === 'yes';
            }
            else {
                let authorizationParts = authorization.split('.');
                if (consents.hasOwnProperty(authorizationParts[0])) {
                    let authorizationType = consents[authorizationParts[0]];
                    if (authorizationType && authorizationType.hasOwnProperty(authorizationParts[1])) {
                        authorized = authorizationType[authorizationParts[1]] === 'yes';
                    }
                }
            }
        }

        if (debugMode)
            console.log("RGPD >> ", authorization, " authorization : ", authorized);

        if (authorized && callbackIfTrue !== null) {
            callbackIfTrue();
        }
        else {
            // if (!authorized) {
                // purge cookies
                // this.cookie.purge(authorization);
            // }

            if(!callbacks[authorization]) {
                callbacks[authorization] = [];
            }
            callbacks[authorization].push(callbackIfTrue);

            if (callbackIfFalse !== null) {
                callbackIfFalse();
            }
        }

        return authorized;
    }

    static runCallbacks() {

        if( debugMode )
            console.log("RGPD >> Runnning callbacks");

        for(let authorization in callbacks) {
            let runCallBacks = false;
            if( consents.hasOwnProperty(authorization) && consents[authorization] === 'yes' ) {
                runCallBacks = true;
            }
            else {
                let authorizationParts = authorization.split('.');
                if (consents.hasOwnProperty(authorizationParts[0])) {
                    let authorizationType = consents[authorizationParts[0]];
                    if (authorizationType && authorizationType.hasOwnProperty(authorizationParts[1]) && authorizationType[authorizationParts[1]] === 'yes') {
                        runCallBacks = true;
                    }
                }
            }
            if (runCallBacks) {
                for(let i=0; i<callbacks[authorization].length; i++) {
                    callbacks[authorization][i]();
                }

                callbacks[authorization] = [];
            }
        }
    }

    static registerEventListeners() {
        $('a[href="#'+cookieAnchor+'"]').on("click",
            (event) => {
                event.preventDefault();
                Rgpd.showPopup();
        });
    }

    static showBox() {
        if(box) {
            box.show();
        } else {
            Rgpd.loadHtml().then(function(){
                Rgpd.showBox();
            });
        }
    }

    static hideBox() {
        if(box) {
            box.hide();
        }
    }

    static loadHtml() {
        let promise = $.Deferred();

        $.ajax({
            method: 'POST',
            url: Routing.generate('rgpd'),
            data: {
                'consents': consents,
            },
            success: function(data) {
                if( data.status === 'success' ){

                    $('body').append(data.html);

                    box = $('#cookies-box');
                    popup = $('#cookies-popup');

                    // bandeau

                    $('#show-cookies-dialog', box).on('click', (e) => {
                        if( debugMode )
                            console.log('RGPD >> opening cookie settings...');

                        e.preventDefault();
                        Rgpd.hideBox();
                        Rgpd.showPopup();
                    });

                    $('#accept-cookies', box).on('click', (e) => {
                        if (debugMode)
                            console.log('RGPD >> all cookies accepted');

                        e.preventDefault();

                        Rgpd.setAll('yes');
                        Rgpd.saveConsent();

                        Rgpd.hideBox();
                        Rgpd.hidePopup();
                    });

                    $('#dismiss-cookies', box).on('click', (e) => {
                        if (debugMode)
                            console.log('RGPD >> all cookies dismissed');

                        e.preventDefault();

                        Rgpd.setAll('no');
                        Rgpd.saveConsent();

                        Rgpd.hideBox();
                        Rgpd.hidePopup();
                    });

                    // popup

                    $('#dialog-close', popup).on('click', (e) => {
                        e.preventDefault();
                        Rgpd.hidePopup();
                    });

                    $('button.accept-cookies-all', popup).on('click', function (e) {
                        e.preventDefault();

                        for(let authorizationCategory in temporaryConsents) {
                            if(typeof temporaryConsents[authorizationCategory] === 'object') {
                                for(let authorizationService in temporaryConsents[authorizationCategory]) {
                                    temporaryConsents[authorizationCategory][authorizationService] = 'yes';
                                }
                            } else {
                                temporaryConsents[authorizationCategory] = 'yes';
                            }
                        }

                        Rgpd.updatePopup();

                        if(autoSave) {
                            Rgpd.readConsentFromPopup();
                            Rgpd.saveConsent();
                        }
                    });

                    $('button.decline-cookies-all', popup).on('click', function (e) {
                        e.preventDefault();

                        for(let authorizationCategory in temporaryConsents) {
                            if(typeof temporaryConsents[authorizationCategory] === 'object') {
                                for(let authorizationService in temporaryConsents[authorizationCategory]) {
                                    temporaryConsents[authorizationCategory][authorizationService] = 'no';
                                }
                            } else {
                                temporaryConsents[authorizationCategory] = 'no';
                            }
                        }

                        Rgpd.updatePopup();

                        if(autoSave) {
                            Rgpd.readConsentFromPopup();
                            Rgpd.saveConsent();
                        }
                    });

                    $('button.accept-cookie', popup).on('click', function (e) {
                        e.preventDefault();

                        let category = $(this).data('category'),
                            service = $(this).data('service');

                        if( debugMode )
                            console.log('RGPD >> accept '+category+'.'+service+' cookies ...');

                        temporaryConsents[category][service] = 'yes';

                        Rgpd.updatePopup();

                        if(autoSave) {
                            Rgpd.readConsentFromPopup();
                            Rgpd.saveConsent();
                        }
                    });

                    $('button.decline-cookie', popup).on('click', function(e) {
                        e.preventDefault();

                        let category = $(this).data('category'),
                            service = $(this).data('service');

                        if( debugMode )
                            console.log('RGPD >> decline '+category+'.'+service+' cookies ...');

                        temporaryConsents[category][service] = 'no';

                        Rgpd.updatePopup();

                        if(autoSave) {
                            Rgpd.readConsentFromPopup();
                            Rgpd.saveConsent();
                        }
                    });

                    popup.dialog({
                        width: 'auto',
                        height: 'auto',
                        autoOpen: false,
                        modal: true,
                        title: false,
                        resizable: false,
                        show:300,
                        hide:300,
                        open: function (event, ui) {
                            $(".ui-widget-overlay").click(function () {
                                $('#cookies-popup').dialog('close');
                            });
                        }
                    });

                    promise.resolve();

                    // document.querySelector('body').dispatchEvent(new CustomEvent('registerEventListeners'));

                } else {
                    if( debugMode )
                        console.log('RGPD >> error getting popup HTML (bad response)');

                    promise.reject();
                }

            },
            error: function(){
                if( debugMode )
                    console.log('RGPD >> error getting popup HTML (no response)');
                promise.reject();
            }
        });

        return promise;
    }

    static readConsentFromPopup() {
        consents = $.extend(true, { }, temporaryConsents);
    }

    static setAll(status) {
        for(let authorizationCategory in consents) {
            if(typeof consents[authorizationCategory] === 'object') {
                for(let authorizationService in consents[authorizationCategory]) {
                    consents[authorizationCategory][authorizationService] = status;
                }
            } else {
                consents[authorizationCategory] = status;
            }
        }
        if(debugMode) {
            console.log(consents);
        }
    }

    static updateAllButtons() {
        let allYes = true, allNo = true;
        for(let authorizationCategory in temporaryConsents) {
            if(typeof temporaryConsents[authorizationCategory] === 'object') {
                for(let authorizationService in temporaryConsents[authorizationCategory]) {
                    if(temporaryConsents[authorizationCategory][authorizationService] === 'yes') {
                        allNo = false;
                    }
                    else if(temporaryConsents[authorizationCategory][authorizationService] === 'no') {
                        allYes = false;
                    } else {
                        allNo = false;
                        allYes = false;
                    }
                }
            } else {
                if(temporaryConsents[authorizationCategory] === 'yes') {
                    allNo = false;
                }
                else if(temporaryConsents[authorizationCategory] === 'no') {
                    allYes = false;
                } else {
                    allNo = false;
                    allYes = false;
                }
            }
        }

        $('button.accept-cookies-all', popup).toggleClass('active', allYes);
        $('button.decline-cookies-all', popup).toggleClass('active', allNo);
    }

    static updatePopup() {
        for(let authorizationCategory in temporaryConsents) {
            if(typeof temporaryConsents[authorizationCategory] === 'object') {
                for(let authorizationService in temporaryConsents[authorizationCategory]) {
                    $('button[data-category='+authorizationCategory+'][data-service='+authorizationService+']', popup).removeClass('active');
                    if(temporaryConsents[authorizationCategory][authorizationService] === 'yes') {
                        $('button.accept-cookie[data-category='+authorizationCategory+'][data-service='+authorizationService+']', popup).addClass('active');
                    }
                    else if(temporaryConsents[authorizationCategory][authorizationService] === 'no') {
                        $('button.decline-cookie[data-category='+authorizationCategory+'][data-service='+authorizationService+']', popup).addClass('active');
                    }
                }
            } else {
                $('button[data-category='+authorizationCategory+']', popup).removeClass('active');
                if(temporaryConsents[authorizationCategory] === 'yes') {
                    $('button.accept-cookie[data-category='+authorizationCategory+']', popup).addClass('active');
                }
                else if(temporaryConsents[authorizationCategory] === 'no') {
                    $('button.decline-cookie[data-category='+authorizationCategory+']', popup).addClass('active');
                }
            }
        }

        Rgpd.updateAllButtons();
    }

    static showPopup() {
        temporaryConsents = $.extend(true, { }, consents);
        Rgpd.updatePopup();

        if(popup) {
            popup.dialog('open');
        } else {
            Rgpd.loadHtml().then(function(){
                Rgpd.showPopup();
            });
        }
    }

    static hidePopup() {
        if(popup) {
            popup.dialog('close');
        }
    }

    static loadConsent() {
        const cookieContent = decodeURIComponent(document.cookie);
        const cookieParts = cookieContent.split(";");

        consents = defaultConsents;

        for (let i in cookieParts) {
            let cookie = cookieParts[i].trim();
            if(cookie.indexOf(cookieName+'=') === 0) {

                let cookieValue = cookie.replace(/^[^=]+./, '');
                if(cookieValue) {
                    try {
                        let cookieConsents = JSON.parse(cookieValue);

                        for(let authorizationCategory in cookieConsents) {
                            if(typeof cookieConsents[authorizationCategory] === 'object') {
                                for(let authorizationService in cookieConsents[authorizationCategory]) {
                                    consents[authorizationCategory][authorizationService] = cookieConsents[authorizationCategory][authorizationService]
                                }
                            } else {
                                consents[authorizationCategory] = cookieConsents[authorizationCategory]
                            }
                        }
                    } catch (e) {
                    }
                }
            }
        }
    }



    static saveConsent() {
        let d = new Date();

        d.setTime(d.getTime() + cookieDuration * 1000);

        let hostName = window.location.hostname;
        hostName = hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);

        document.cookie = cookieName + "=" + JSON.stringify(consents) + ";" + "expires=" + d.toUTCString() + ";path=/;domain=" + hostName;

        Rgpd.runCallbacks();
    }



}

export { Rgpd };
