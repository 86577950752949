import { Rgpd } from './base.rgpd';

let infowindow;
let map;
let mapZoom;
let mapMarkers;
let mapMarkerCircles;
let mapPicto;
let mapPictoSelected;
let mapPictoCluster;
let mapMarkerCluster;
let mapBounds;

let mapPictoWidth = 44;
let mapPictoHeight = 49;
let mapPictoClusterWidth = 48;
let mapPictoClusterHeight = 49;

let clustersEnabled = false;
let mapCirclePrimaryColor = '#C29247'; // Site primary color

let googleMapScriptsLoaded = false;

$( function() {

    $('.map-container').each(function() {
        if ($(this).hasClass('lazyload')) {
            $(this).on('lazybeforeunveil', function(){
                InitScript();
            });
        }
        else {
            InitScript();
        }

        $(this).on('google_map_scripts_loaded', function(){
            if(!$(this).find('.map').hasClass('rendered')) {
                InitMaps($(this));
            }
        });
    });

});

let InitScript = function(){

    if(googleMapScriptsLoaded) {
        return;
    }

    Rgpd.test('stats.maps', () => {
        if($('#google-map-script').length > 0) return;

        let scriptTag = document.createElement("script");

        scriptTag.onload = function () {
            googleMapScriptsLoaded = true;
            $('.map-container').trigger('google_map_scripts_loaded');
        }

        scriptTag.id = 'google-map-script';
        scriptTag.type = 'text/javascript';
        scriptTag.src = 'https://maps.google.com/maps/api/js?key=' + ($('html').data('google-map-key') || '');

        document.getElementsByTagName("head")[0].appendChild(scriptTag);
    }, () => {
        $('.map-container').find('div.map-rgpd-advert').removeClass('hidden');
    });
}

let InitMaps = function(el) {

    Rgpd.test('stats.maps', () => {

            // TODO: à supprimer ? Pas utilisé ?
            // if (window.geocoder === undefined) {
            //     window.geocoder = new google.maps.Geocoder();
            // }

            let map = el.find('.map');
            let mapRgpdAdvert = el.find('div.map-rgpd-advert');

            if (!map.hasClass('rendered')) {
                render_map(map);
                map.addClass('rendered');
            }

            mapRgpdAdvert.addClass('hidden');
        }
        , () => {
            el.find('div.map-rgpd-advert').removeClass('hidden');
        });
};

/*
*  render_map
*
*  This function will render a Google Map onto the selected jQuery element
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$el (jQuery element)
*  @return	n/a
*/
let render_map = function (el) {
    // var
    mapPicto = el.data('picto');
    mapPictoSelected = el.data('picto-selected');
    mapPictoCluster = el.data('picto-cluster');
    let markers = el.find('.marker');
    let markerCircles = el.find('.marker-circle');
    let styles = el.data('style');
    mapZoom = el.data('zoom');
    let controlsEnabled = el.data('controlsEnabled');
    clustersEnabled = el.data('clustersEnabled');

    // vars
    let args = {
        zoom		: mapZoom,
        maxZoom		: mapZoom + 3,
        center		: new google.maps.LatLng(0, 0),
        mapTypeId	: google.maps.MapTypeId.ROADMAP,
        styles      : styles,
        // disableDefaultUI: false
        disableDefaultUI: !controlsEnabled,
        mapTypeControl: controlsEnabled,
        rotateControl: controlsEnabled,
        zoomControl: controlsEnabled,
        scaleControl: controlsEnabled,
        streetViewControl: controlsEnabled,
        fullscreenControl: controlsEnabled,
        gestureHandling: controlsEnabled ? 'cooperative' : 'none',
    };

    // create map
    map = new google.maps.Map( el[0], args);

    // add a markers reference
    map.markers = [];
    map.markerCircles = [];
    map.picto = mapPicto;
    map.pictoSelected = mapPictoSelected || mapPicto;
    map.pictoCluster = mapPictoCluster || mapPictoSelected;
    map.currentMarker = null;

    // add markers
    markers.each(function(){
        add_marker( $(this), map );
    });

    // add markerCircles
    markerCircles.each(function(){
        add_marker_circle( $(this), map );
    });

    mapMarkers = map.markers;
    mapMarkerCircles = map.markerCircles;

    // clusters
    if (clustersEnabled) {
        init_clusters();
    }

    // center map
    center_map( map, mapZoom );

    return map;
};

/*
*  add_marker
*
*  This function will add a marker to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$marker (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/
let add_marker = function(marker, map) {
    if( marker.data('lat') !== '' && marker.data('lng') !== '' ) {

        // var
        let latlng = new google.maps.LatLng(marker.data('lat'), marker.data('lng'));

        // create marker
        let markerData = new google.maps.Marker({
            position: latlng,
            map: map,
            id: marker.data('marker-id'),
            icon: {
                url: marker.data('picto') ? marker.data('picto') : map.picto,
                scaledSize: new google.maps.Size(mapPictoWidth, mapPictoHeight),
                size: new google.maps.Size(mapPictoWidth, mapPictoHeight)
            }
        });

        // add to array
        map.markers.push(markerData);

        if (marker.data('selected')) {
            activate_marker(markerData);
            map.currentMarker = markerData;
        }

        // if marker contains HTML, add it to an infoWindow
        if (marker.html() && marker.html() !== '') {
            // show info window when marker is clicked
            google.maps.event.addListener(markerData, 'click', function () {
                if (infowindow) {
                    infowindow.close();
                }
                infowindow = new google.maps.InfoWindow({
                    content: marker.html()
                });
                infowindow.addListener('closeclick', function () {
                    if (map.currentMarker) {
                        desactivate_marker(map.currentMarker);
                    }
                });
                infowindow.open(map, markerData);
                if (map.currentMarker) {
                    desactivate_marker(map.currentMarker);
                }
                activate_marker(markerData);
                map.currentMarker = markerData;

                map.addListener('click', (mapsMouseEvent) => {
                    // Close the current InfoWindow.
                    infowindow.close();
                });
            });
        }
    }
};

/*
*  add_marker_circle
*
*  This function will add a marker cirlcle to the selected Google Map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	$markerCircle (jQuery element)
*  @param	map (Google Map object)
*  @return	n/a
*/
let add_marker_circle = function(markerCircle, map) {
    // var
    let latlng = new google.maps.LatLng( markerCircle.data('lat'), markerCircle.data('lng') );

    // create invisible marker
    let markerData = new google.maps.Marker({
        position	: latlng,
        map			: map,
        id          : markerCircle.data('marker-id'),
        visible     : false
    });

    // create markerCircle
    let markerCircleData = new google.maps.Circle({
        center   	: latlng,
        radius   	: markerCircle.data('radius'),
        map			: map,
        id          : markerCircle.data('marker-id'),
        fillColor   : mapCirclePrimaryColor,
        fillOpacity : 0.25,
        strokeColoe : mapCirclePrimaryColor,
        strokeOpacity : 0.5,
        strokeWeight : 1
    });

    // add to arrays
    map.markers.push( markerData );
    map.markerCircles.push( markerCircleData );
};

/*
*  center_map
*
*  This function will center the map, showing all markers attached to this map
*
*  @type	function
*  @date	8/11/2013
*  @since	4.3.0
*
*  @param	map (Google Map object)
*  @return	n/a
*/
let center_map = function(map, zoom) {
    // vars
    mapBounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){
        let latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
        mapBounds.extend( latlng );
    });

    // only 1 marker?
    if( map.markers.length === 1 )
    {
        // set center of map
        map.setCenter( mapBounds.getCenter() );
        map.setZoom( zoom );
    }
    else
    {
        // fit to bounds
        map.fitBounds( mapBounds );
        // map.setZoom( zoom );
    }
};

let ensureVisible = function(elem, container) {
    let scrollTo = elem.offset().top - container.offset().top + $(container).scrollTop();

    $(container).stop().animate({
        scrollTop: scrollTo
    }, 300);
};

let activate_marker = function(marker) {
    marker.setIcon({
        url: map.pictoSelected,
        scaledSize: new google.maps.Size(mapPictoWidth, mapPictoHeight),
        size: new google.maps.Size(mapPictoWidth, mapPictoHeight)
    });
};
let desactivate_marker = function(marker) {
    marker.setIcon({
        url: map.picto,
        scaledSize: new google.maps.Size(mapPictoWidth, mapPictoHeight),
        size: new google.maps.Size(mapPictoWidth, mapPictoHeight)
    });
};

let init_clusters = function() {
    if (mapMarkerCluster) {
        mapMarkerCluster.clearMarkers();
    }

    let markersToCluster = [];
    for (let i = 0; i < map.markers.length; i++) {
        if (map.markers[i].visible) {
            markersToCluster.push(map.markers[i]);
        }
    }

    mapMarkerCluster = new MarkerClusterer(map, markersToCluster,
        {
            imagePath: map.pictoCluster,
            styles: [{
                url: map.pictoCluster,
                width: mapPictoClusterWidth,
                height: mapPictoClusterHeight
            }],
        }
    );
};