let sNavBurgerLimit = 1024; // $s-nav-burger-limit in _var.scss

function megaNavOpen(link) {
    let anchor = link.data('anchor');
    if (anchor) {
        let megaNav = $('#' + anchor + '-mega-nav');
        if (megaNav) {
            $('.mega-nav.visible').removeClass('visible');
            $('nav .mega-menu > li > span.hover').removeClass('hover');
            $('nav.header-menu > ul').addClass('hide');
            $('.additional-content').addClass('hide');
            megaNav.addClass('visible');
            $('header').addClass('expanded');
        }
    }
}

function megaNavClick(link) {
    $('nav .mega-menu > li > span.hover').removeClass('hover');
    link.addClass('hover');
    let submenu = link.parent().find('.submenu').first();
    if (link.hasClass('back-to-main-menu')) {
        link.closest('.mega-nav').removeClass('visible');
        $('header').removeClass('expanded');
        $('nav.header-menu > ul').removeClass('hide');
        $('.additional-content').removeClass('hide');
    }
    else if (link.hasClass('back')) {
        link.closest('ul').removeClass('visible').parent().parent().removeClass('hide');
    }
    else if (submenu && submenu.length) {
        $('.submenu.visible').removeClass('visible');
        $('nav .mega-menu').removeClass('hide').removeClass('visible');
        link.closest('.mega-menu').addClass('hide');
        submenu.addClass('visible');
    }
}

function megaNavClose() {
    $('.mega-nav.visible').removeClass('visible');
    $('nav .mega-menu > li > span.hover').removeClass('hover');
    $('nav .mega-menu').removeClass('hide').removeClass('visible');
    $('nav.header-menu > ul > li.expanded').removeClass('expanded');
    $('header').removeClass('expanded');
}

$(function () {
    $('.touchevents nav.header-menu > ul').on('tap', 'span', function () {
        megaNavOpen($(this));
    });
    $('.no-touchevents nav.header-menu > ul').on('mouseenter', 'span', function () {
        if (window.matchMedia('(min-width: '+sNavBurgerLimit+'px)').matches) {
            megaNavOpen($(this));
        }
    });
    $('nav.header-menu > ul').on('click', 'span', function () {
        megaNavOpen($(this));
    });
    $('.touchevents nav .mega-menu').on('tap', 'span', function () {
        megaNavClick($(this));
    });
    $('.no-touchevents nav .mega-menu').on('mouseenter', 'span', function () {
        if (window.matchMedia('(min-width: '+sNavBurgerLimit+'px)').matches) {
            megaNavClick($(this));
        }
    });
    $('nav .mega-menu').on('click', 'span', function () {
        if (window.matchMedia('(max-width: '+(sNavBurgerLimit-1)+'px)').matches) {
            megaNavClick($(this));
        }
    });
    $('.no-touchevents nav.header-menu > ul').on('mouseenter', 'span', function () {
        if (window.matchMedia('(min-width: '+sNavBurgerLimit+'px)').matches) {
            let anchor = $(this).data('anchor');
            if (anchor) {
                $('nav.header-menu > ul > li.expanded').removeClass('expanded');
                $(this).parent().addClass('expanded');
                $('header').addClass('expanded');
            }
            else {
                megaNavClose();
            }
        }
    }).on('mouseenter', 'a', function () {
        if (window.matchMedia('(min-width: '+sNavBurgerLimit+'px)').matches) {
            megaNavClose();
        }
    });
    $('#nav-content').on('mouseleave', function () {
        if (window.matchMedia('(min-width: '+sNavBurgerLimit+'px)').matches) {
            megaNavClose();
        }
    });
    $('nav .back-to-main-menu').on('click', 'span', function () {
        let anchor = $(this).data('anchor');
        if (anchor) {
            let megaNav = $('#' + anchor + '-mega-nav');
            if (megaNav) {
                $('nav.header-menu > ul').next('.mega-nav-back').removeClass('visible');
                $('nav.header-menu > ul').removeClass('hide');
                $('.additional-content').removeClass('hide');
                megaNav.removeClass('visible');
                $('header').removeClass('expanded');
                setTimeout(function () {
                    $('nav.header-menu > ul').next('.mega-nav-back').html('');
                }, 500);
            }
        }
    });
    $('.mega-nav').on('click', '.title', function () {
        if ($(this).next('.content')) {
            if ($(this).next('.content').hasClass('visible')) {
                $('.mega-nav').find('.content.visible').removeClass('visible');
            }
            else {
                $('.mega-nav').find('.content.visible').removeClass('visible');
                $('.mega-nav').find('.title > i.icon-arrow-angle-right').addClass('down').removeClass('up');
                $(this).next('.content').addClass('visible');
            }
            $(this).find('i.icon-arrow-angle-right').toggleClass('down').toggleClass('up');
        }
    });
    $('nav.menu [data-anchor]').each(function () {
        const anchor = $(this).data('anchor');
        if ($('.mega-nav' + anchor)) {
            $(this).parent().addClass('mega');
        }
    });
    $('.additional-content').on('mouseenter', function() {
        if (window.matchMedia('(min-width: '+sNavBurgerLimit+'px)').matches) {
            megaNavClose();
        }
    });

    $('.mega-nav').show();
});