(function() {
    var LocaleSwitcher = function(element) {
        this.element = element;
        this.select = this.element.getElementsByTagName('select')[0];
        this.options = this.select.getElementsByTagName('option');
        this.selectedOption = getSelectedOptionText(this);
        this.pickerId = this.select.getAttribute('id');
        this.trigger = false;
        this.dropdown = false;
        this.firstLanguage = false;
        // dropdown arrow inside the button element
        this.svgPath = '<svg viewBox="0 0 16 16"><polygon points="3,5 8,11 13,5 "></polygon></svg>';
        initLocaleSwitcher(this);
        initLocaleSwitcherEvents(this);
    };

    function initLocaleSwitcher(picker) {
        // create the HTML for the custom dropdown element
        picker.element.insertAdjacentHTML('beforeend', initButtonPicker(picker) + initListPicker(picker));

        // save picker elements
        picker.dropdown = picker.element.getElementsByClassName('locale-switcher__dropdown')[0];
        picker.firstLanguage = picker.dropdown.getElementsByClassName('locale-switcher__item')[0];
        picker.trigger = picker.element.getElementsByClassName('locale-switcher__button')[0];
    };

    function initLocaleSwitcherEvents(picker) {
        // make sure to add the icon class to the arrow dropdown inside the button element
        // Util.addClass(picker.trigger.getElementsByTagName('svg')[0], 'icon');
        // language selection in dropdown
        // ⚠️ Important: you need to modify this function in production
        initLanguageSelection(picker);

        // click events
        picker.trigger.addEventListener('click', function(){
            toggleLocaleSwitcher(picker, false);
        });
    };

    function toggleLocaleSwitcher(picker, bool) {
        var ariaExpanded;
        if(bool) {
            ariaExpanded = bool;
        } else {
            ariaExpanded = picker.trigger.getAttribute('aria-expanded') == 'true' ? 'false' : 'true';
        }
        picker.trigger.setAttribute('aria-expanded', ariaExpanded);
        if(ariaExpanded == 'true') {
            picker.firstLanguage.focus(); // fallback if transition is not supported
            picker.dropdown.addEventListener('transitionend', function cb(){
                picker.firstLanguage.focus();
                picker.dropdown.removeEventListener('transitionend', cb);
            });
        }
    };

    function checkLocaleSwitcherClick(picker, target) { // if user clicks outside the language picker -> close it
        if( !picker.element.contains(target) ) toggleLocaleSwitcher(picker, 'false');
    };

    function moveFocusToPickerTrigger(picker) {
        if(picker.trigger.getAttribute('aria-expanded') == 'false') return;
        if(document.activeElement.closest('.locale-switcher__dropdown') == picker.dropdown) picker.trigger.focus();
    };

    function initButtonPicker(picker) { // create the button element -> picker trigger
        // check if we need to add custom classes to the button trigger
        var customClasses = picker.element.getAttribute('data-trigger-class') ? ' '+picker.element.getAttribute('data-trigger-class') : '';

        var button = '<button class="locale-switcher__button'+customClasses+'" aria-label="'+picker.select.value+' '+picker.element.getElementsByTagName('label')[0].textContent+'" aria-expanded="false" aria-contols="'+picker.pickerId+'-dropdown">';
        button = button + '<span aria-hidden="true" class="locale-switcher__label">'+picker.selectedOption;
        // button = button +picker.svgPath+'</span>';
        button = button + '<i aria-hidden="true" class="icon-arrow-angle-down"></i></span>';
        return button + '</button>';
    };

    function initListPicker(picker) { // create language picker dropdown
        var list = '<div class="locale-switcher__dropdown" aria-describedby="'+picker.pickerId+'-description" id="'+picker.pickerId+'-dropdown">';
        list = list + '<p class="sr-only" id="'+picker.pickerId+'-description">'+picker.element.getElementsByTagName('label')[0].textContent+'</p>';
        list = list + '<ul class="locale-switcher__list" role="listbox">';
        for(var i = 0; i < picker.options.length; i++) {
            var selected = picker.options[i].hasAttribute('selected') ? ' aria-selected="true"' : '',
                language = picker.options[i].getAttribute('lang');
            list = list + '<li><a lang="'+language+'" hreflang="'+language+'" href="'+getLanguageUrl(picker.options[i])+'"'+selected+' role="option" title="'+picker.options[i].getAttribute('title')+'" data-value="'+picker.options[i].value+'" class="locale-switcher__item"><span>'+picker.options[i].text+'</span></a></li>';
        };
        return list;
    };

    function getSelectedOptionText(picker) { // used to initialize the label of the picker trigger button
        var label = '';
        if('selectedIndex' in picker.select) {
            label = picker.options[picker.select.selectedIndex].text;
        } else {
            label = picker.select.querySelector('option[selected]').text;
        }
        return label;
    };

    function getLanguageUrl(option) {
        // ⚠️ Important: You should replace this return value with the real link to your website in the selected language
        // option.value gives you the value of the language that you can use to create your real url (e.g, 'english' or 'italiano')
        return option.getAttribute('data-url');
        // return '#';
    };

    function initLanguageSelection(picker) {
        picker.element.getElementsByClassName('locale-switcher__list')[0].addEventListener('click', function(event){
            var language = event.target.closest('.locale-switcher__item');
            if(!language) return;

            if(language.hasAttribute('aria-selected') && language.getAttribute('aria-selected') == 'true') {
                // selecting the same language
                event.preventDefault();
                picker.trigger.setAttribute('aria-expanded', 'false'); // hide dropdown
            /*} else {
                // ⚠️ Important: this 'else' code needs to be removed in production.
                // The user has to be redirected to the new url -> nothing to do here
                event.preventDefault();
                picker.element.getElementsByClassName('locale-switcher__list')[0].querySelector('[aria-selected="true"]').removeAttribute('aria-selected');
                language.setAttribute('aria-selected', 'true');
                picker.trigger.getElementsByClassName('locale-switcher__label')[0].setAttribute('class', 'locale-switcher__label locale-switcher__flag locale-switcher__flag--'+language.getAttribute('data-value'));
                picker.trigger.getElementsByClassName('locale-switcher__label')[0].getElementsByTagName('em')[0].textContent = language.textContent;
                picker.trigger.setAttribute('aria-expanded', 'false');*/
            }
        });
    };

    //initialize the LocaleSwitcher objects
    var languagePicker = document.getElementsByClassName('js-locale-switcher');
    if( languagePicker.length > 0 ) {
        var pickerArray = [];
        for( var i = 0; i < languagePicker.length; i++) {
            (function(i){pickerArray.push(new LocaleSwitcher(languagePicker[i]));})(i);
        }

        // listen for key events
        window.addEventListener('keyup', function(event){
            if( event.keyCode && event.keyCode == 27 || event.key && event.key.toLowerCase() == 'escape' ) {
                // close language picker on 'Esc'
                pickerArray.forEach(function(element){
                    moveFocusToPickerTrigger(element); // if focus is within dropdown, move it to dropdown trigger
                    toggleLocaleSwitcher(element, 'false'); // close dropdown
                });
            }
        });
        // close language picker when clicking outside it
        window.addEventListener('click', function(event){
            pickerArray.forEach(function(element){
                checkLocaleSwitcherClick(element, event.target);
            });
        });
    }
}());