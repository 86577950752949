
import { Rgpd } from './base.rgpd';

class Tracking {

    static init(code) {

        window.dataLayer = window.dataLayer || [];

        if(code) {
            if (code.match(/^GTM-/)) {

                (function (w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
                    let f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', code);

                // TODO gérer tous les services !
                Rgpd.test('stats.analytics', function () {
                    window.dataLayer.push({
                        'event': 'consent_analytics'
                    });
                });
            }
            else if (code.match(/^(GA-|G-)/)) {

                Rgpd.test('stats.analytics', function () {
                    window.addScript('https://www.googletagmanager.com/gtag/js?id=' + code);

                    function gtag() {
                        dataLayer.push(arguments);
                    }

                    gtag('js', new Date());
                    gtag('config', code);
                });
            }
            else {
                console.log('Tracking : Unsupported tracking code : ' + code)
            }
        }
    }
}

export { Tracking };
