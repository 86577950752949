window.$ = window.jQuery = require('jquery');

require('jquery.easing');
require('./modernizr-custom');

require('./jquery.dropdown.min');

let lightbox= require('lightbox2');
let jQueryBridget = require('jquery-bridget');
let Masonry = require('masonry-layout');
jQueryBridget( 'masonry', Masonry, $ );
let imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );
const simpleParallax = require('simple-parallax-js');

// require('sticky-sidebar/src/jquery.sticky-sidebar');

require('./base.nav');
require('./base.popup');
require('./base.form');
require('./base.sliders');
// require('./base.video');
require('./base.rgpd');
require('./base.map');
require('./base.locale-switcher');



import { Rgpd } from './base.rgpd';
import { Tracking } from './base.tracking';
import * as Video from './base.video';
import 'lazysizes';

Rgpd.init(false, true, 'pure-rgpd-kookki', 'rgpd', {
    'stats': {
        // 'analytics': null,
        'maps': null,
    },
    // 'ads': {
    //     'facebook': null,
    // },
    'video': {
        'youtube': null,
        'vimeo': null,
    },
});

Tracking.init($('html').data('tracking-code'));

Video.init();


let scrollTimeOut;
let prevScrollpos = window.pageYOffset;
let prevScrollposDelayed = prevScrollpos;

let hash = window.location.hash;
window.location.hash = '';
hash = hash.replace('#', '');

window.addScript = function(src) {
    let s = document.createElement( 'script' );
    s.setAttribute( 'src', src );
    document.body.appendChild(s);
};

window.handleScroll = function() {

    let body = $('body');

    let currentScrollPos = window.pageYOffset;
    let winHeight = $(window).height();
    let docHeight = $(document).height();
    let scrollBottom = docHeight - (currentScrollPos + winHeight);

    let navBtn = $('#nav-button');
    let scrollUpBtn = $('#scroll-up');

    if (Math.abs(prevScrollposDelayed - currentScrollPos) > 300) {
        scrollUpBtn.removeClass('visible');
    }
    if (scrollTimeOut) {
        clearTimeout(scrollTimeOut);
    }
    scrollTimeOut = window.setTimeout(function() {
        prevScrollposDelayed = currentScrollPos;
        navBtn.addClass('visible');
        if (currentScrollPos > 50) {
            scrollUpBtn.addClass('visible');
        }
        else {
            scrollUpBtn.removeClass('visible');
        }
    }, 500);

    if (currentScrollPos > 50) {
        body.addClass('scrolling');
        navBtn.removeClass('visible');
        // scrollUpBtn.removeClass('visible');

        if (prevScrollpos > currentScrollPos) {
            body.addClass('scrolling-up');
            body.removeClass('scrolling-down');
        }
        else {
            body.removeClass('scrolling-up');
            body.addClass('scrolling-down');
        }
    }
    else {
        body.removeClass('scrolling');
        body.removeClass('scrolling-up');
        body.removeClass('scrolling-down');
        // scrollUpBtn.removeClass('visible');
    }
    prevScrollpos = currentScrollPos;
};

window.handleResize = function() {
};

window.InitStickySidebar = function(stickyEl) {
    if (stickyEl) {
        let containerSelector = stickyEl.data('sticky-column-container') | '.sticky-column-container';
        let minWidth = stickyEl.data('sticky-min-width');
        let topSpacing = $('header').innerHeight() + 20;
        if ($('#header-top').length && $('#header-top').is(':visible')) {
            topSpacing += $('#header-top').innerHeight();
        }
        let targetHeightToAdd = stickyEl.data('sticky-target-height-to-add');
        if ($(targetHeightToAdd).length) {
            topSpacing += $(targetHeightToAdd).innerHeight();
        }
        let topSpacingToAdd = stickyEl.data('sticky-top-space-to-add');
        if (topSpacingToAdd) {
            topSpacing += topSpacingToAdd;
        }
        let targetHeightToRemove = stickyEl.data('sticky-target-height-to-remove');
        if ($(targetHeightToRemove).length) {
            topSpacing -= $(targetHeightToRemove).innerHeight();
        }
        let topSpacingToRemove = stickyEl.data('sticky-top-space-to-remove');
        if (topSpacingToRemove) {
            topSpacing -= topSpacingToRemove;
        }
        window.stickySidebar = new StickySidebar('#'+stickyEl.attr('id'), {
            containerSelector: containerSelector,
            minWidth: minWidth | 0,
            topSpacing: topSpacing
        });
    }
};

window.smoothScrollTo = function(hash, customOffset, onlyUp = false, onlyDown = false) {
    let currentScrollPos = window.pageYOffset;
    let offset = parseFloat(customOffset) || 0;
    let target = $('#'+hash);
    target = target.length ? target : $('[name=' + hash +']');

    if (target.length) {
        // offset = offset || $('header').height() || 0;
        offset = offset + $('header').height();

        let targetPos = target.offset().top - offset;

        if ((currentScrollPos > targetPos || !onlyUp) && (currentScrollPos < targetPos || !onlyDown)) {
            $('html,body').animate({
                scrollTop: targetPos
            }, 800, 'easeInOutCubic');

            // Tricky mais permet de vérifier que la target est en haut de page et si ce n'est pas le cas, on relance l'animation.
            // Cela est dû au fait que les images sont en lazyload.
            setTimeout(() => {
                if (Math.abs(target.offset().top - (window.pageYOffset + offset)) > 50) {
                    smoothScrollTo(hash, customOffset, onlyUp, onlyDown);
                }
            }, 800);
        }

        return false;
    }
};

$(function(){

    // Rewrite fos js routing method to inject locale
    // change name of initial method
    Routing.generateImpl = Routing.generate;
    // override generate fonction by adding a default _locale from request locale
    Routing.generate = function (url, params) {
        let paramsExt = {};
        if (params) {
            paramsExt = params;
        }
        if (!paramsExt._locale) {
            paramsExt._locale = $('html').attr('lang');
        }
        return Routing.generateImpl(url, paramsExt);
    }

    $(window).on('load', function () {
        if (hash && hash !== '_=_') {
            setTimeout(function() {
                window.smoothScrollTo(hash);
                window.location.hash = hash;
            }, 100);
            if (hash === 'video') {
                $('a.button-video').first().click();
            }
        }
    });

    $(window).on('scroll', handleScroll);
    handleScroll();

    $(window).on('resize', handleResize);
    handleResize();

    let viewportWidth = $(document).innerWidth();
    let maxWidth = viewportWidth - 20;
    if (viewportWidth >= 768) {
        maxWidth = viewportWidth - 270;
    }
    lightbox.option({
        albumLabel: "%1/%2",
        fadeDuration: 300,
        imageFadeDuration: 300,
        resizeDuration: 500,
        wrapAround: false,
        maxWidth: maxWidth,
        positionFromTop: 135,
        disableScrolling: true,
    });


    // FAQ
    let faqAnimationSpeed = 500;
    $('.content-block.faq.collapsed').on('click', '.faq-item', function () {

        let faqItem = $(this);
        let faqItems = faqItem.parent();
        let answer = faqItem.find('.answer');

        // Close all active items (excluding the currently clicked element)
        faqItems.find('.faq-item.active').not(faqItem).removeClass('active').find('.answer').stop().slideUp(faqAnimationSpeed);

        if (faqItem.hasClass('active')) {
            faqItem.removeClass('active');
            answer.stop().slideUp(faqAnimationSpeed);
        }
        else {
            faqItem.addClass('active');
            answer.stop().slideDown(faqAnimationSpeed);
        }
    });

    // BEGIN - COLLAPSIBLE BLOCKS
    let collapsibleWrapperClass = 'collapsible-wrapper';
    let collapsibleHandleClass = 'collapsible-handle';
    let collapsibleContentClass = 'collapsible-content';
    let collapsibleClosedClass = 'collapsible-closed';
    let collapsibleSpeed = 500;

    $('.' + collapsibleWrapperClass + '.' + collapsibleClosedClass + ' .' + collapsibleContentClass).hide(); // Initial state : hide the content of closed wrappers
    $('body').on('click', '.' + collapsibleHandleClass, function () {
        let $handle = $(this);
        let $wrapper = $(this).closest('.' + collapsibleWrapperClass);
        let $content = $wrapper.find('.' + collapsibleContentClass);

        if(!$wrapper.hasClass('collapsible-mobile-only') || $(window).width() < 768){ // If the specific class is found, activate the toggle behaviour only on small devices
            $wrapper.toggleClass(collapsibleClosedClass);
            if ($wrapper.hasClass(collapsibleClosedClass)) {
                $content.stop().animate({
                    height: 'hide',
                    opacity: 'hide',
                    paddingTop: 'hide'
                }, collapsibleSpeed);
            }
            else {
                $content.stop().animate({
                    height: 'show',
                    opacity: 'show',
                    paddingTop: 'show'
                }, collapsibleSpeed);
            }
        }
    });
    // END - COLLAPSIBLE BLOCKS


    // smooth scrolling
    $('body').on('click', 'a[href*=\\#]:not([href=\\#]):not(".noscroll"):not([href=\\#next])', function() {

        if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            window.smoothScrollTo(this.hash.slice(1), $(this).data('offset'));
        }
    });

    if (!navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i)) {
        let images = document.querySelectorAll('.simple-parallax');

        // https://simpleparallax.com/#installation
        images.forEach(element => {
            let simpleParallaxParams = {
                'scale': element.getAttribute('data-scale') ?? 1.2,
                'orientation': element.getAttribute('data-orientation') ?? 'up',
                'overflow': element.getAttribute('data-overflow') ?? false,
                'delay': element.getAttribute('data-delay') ?? .4,
                'transition': element.getAttribute('data-transition') ?? 'cubic-bezier(0,0,0,1)',
                'customContainer': element.getAttribute('data-customContainer') ?? '',
                'customWrapper': element.getAttribute('data-customWrapper') ?? '',
                'maxTransition': element.getAttribute('data-maxTransition') ?? 0,
            };

            new simpleParallax(element, simpleParallaxParams);
        });
    }

    // Obfuscated links
    // TODO : the href injection seems to mess with the hover animation (on chrome but not on firefox): find a fix
    $(document).on('mouseover click', '.o-link:not(.button-popup)', function(event) {
        if ($(this).data('target')){
            $(this).attr('target', $(this).data('target'));
        }
        if(!$(this).attr('href') || $(this).attr('href') === '#'){ // If the href attribute is not already set
            $(this).attr('href', decodeURIComponent(escape(atob($(this).data('o'))))); // Set the href attribute
        }
    });

    // Load more
    $(document).on('click', 'button.load-more', function(){
        let button = $(this);
        let target = $('#'+button.data('list-target'));
        $.ajax({
            method: 'GET',
            url: button.data('url'),
            success: function(data) {
                let content = $(data.html);

                target.append(content);

                if (data.nextPageUrl) {
                    button.data('url', data.nextPageUrl);
                }
                else {
                    button.closest('.load-more-wrapper').hide();
                }
            }
        });

        return false;
    });


    // Ajax pagination
    $('.ajax-list-content').on('click', '.pagination li a', function(){
        let link = $(this);
        let target = link.closest('.ajax-list').data('list-target');
        $('#' + target + '-list-loader').addClass('bottom').addClass('loading');
        $('#' + target + '-list-content').height($('#' + target + '-list-content').height());
        $.ajax({
            method: 'GET',
            url: link.attr('href'),
            success: function(data) {
                if (data.html) {
                    $('#' + target + '-list-content').html(data.html);
                    window.smoothScrollTo(target + '-list-content', 0, true);
                    $('#' + target + '-list-loader').removeClass('loading')/*.removeClass('bottom')*/;
                    $('#' + target + '-list-content').height('auto');
                }
            }
        });

        return false;
    });


    // Show more
    $('.show-more').each(function() {
        let itemClass = $(this).data('item-class');
        let numToShow = $(this).data('items-to-show');
        let list = $(this).find(itemClass);
        let numInList = list.length;
        let button = $(this).parent().find('.show-more-button');

        list.hide();
        if (numInList > numToShow) {
            button.show();
        }
        list.slice(0, numToShow).show();

        button.click(function(){
            let showing = list.filter(':visible').length;
            list.slice(showing - 1, showing + numToShow).fadeIn();
            let nowShowing = list.filter(':visible').length;
            if (nowShowing >= numInList) {
                button.hide();
            }
        });
    });


    // Scroll up
    $('#scroll-up').on('click', function () {
        $('html,body').animate({
            scrollTop: 0
        }, 500, function() {
            window.location.hash = "";
        });
    });


    // Masonry
    let masonryGrid = $('.masonry-grid').on('lazyloaded', function () {
        masonryGrid.masonry('layout');
    }).masonry({
        itemSelector: '.grid-item',
        gutter: '.gutter-sizer',
        columnWidth: '.grid-sizer',
        isInitLayout: false,
        horizontalOrder: false
    });
    masonryGrid.masonry( 'on', 'layoutComplete', function() {
        setTimeout(function(){
            if (window.stickySidebar) {
                window.stickySidebar.updateSticky();
            }
        }, 500);
    });
    masonryGrid.masonry();


    // Collapsing items
    $(document).on('click', '.collapsing-item .title button.expand', function (e) {
        e.preventDefault();
        let button = $(this);
        let collapsingItem = button.parentsUntil('.collapsing-item').parent();
        if (collapsingItem.hasClass('expanded')) {
            collapsingItem.removeClass('expanded');
            collapsingItem.find('.content').slideUp('medium', function() {
                if (window.stickySidebar) {
                    window.stickySidebar.updateSticky();
                }
            });
        }
        else {
            collapsingItem.addClass('expanded');
            collapsingItem.find('.content').slideDown('medium', function() {
                if (window.stickySidebar) {
                    window.stickySidebar.updateSticky();
                }
                // $('html,body').animate({
                //     scrollTop: $(this).offset().top - 140
                // }, 500);
            });
        }
    });
    $('button.expand-all-collapsing-items').on('click', function() {
        let targetId = $(this).data('target');
        if ($(this).hasClass('expanded')) {
            $('#' + targetId).find('.collapsing-item').each(function () {
                $(this).removeClass('expanded');
                $(this).find('.content').each(function () {
                    $(this).slideUp('medium', function () {
                        if (window.stickySidebar) {
                            window.stickySidebar.updateSticky();
                        }
                    });
                });
            });
            $(this).removeClass('expanded');
        }
        else {
            window.smoothScrollTo(targetId);
            $('#' + targetId).find('.collapsing-item').each(function () {
                $(this).addClass('expanded');
                $(this).find('.content').each(function () {
                    $(this).slideDown('medium', function () {
                        if (window.stickySidebar) {
                            window.stickySidebar.updateSticky();
                        }
                    });
                });
            });
            $(this).addClass('expanded');
        }
    });


    // Sticky sidebar
    $('.sticky-column').each(function () {
        InitStickySidebar($(this));
    });


    // Breadcrumb touchevents scroll
    $('.touchevents .breadcrumb').each(function() {
        $(this).scrollLeft(9999);
        let scrollLeftMax = $(this).scrollLeft();
        if (scrollLeftMax > 0) {
            $(this).attr('data-scroll-left-max', scrollLeftMax);
        }
        else {
            $(this).parent().addClass('scroll-min').addClass('scroll-max');
        }
        $(this).scrollLeft(0);
    }).scroll(function() {
        let scrollLeft = $(this).scrollLeft();
        let scrollLeftMax = $(this).attr('data-scroll-left-max');

        if (scrollLeftMax) {
            if (scrollLeft <= 15) {
                $(this).parent().addClass('scroll-min');
            } else {
                $(this).parent().removeClass('scroll-min');
            }

            if (scrollLeft >= scrollLeftMax - 15) {
                $(this).parent().addClass('scroll-max');
            } else {
                $(this).parent().removeClass('scroll-max');
            }
        }
    });


    // Share box
    $('.touchevents .share-box > button').on('click', function() {
        $(this).parent().addClass('active');
    });
    $('.no-touchevents .share-box').on('mouseenter', function() {
        $(this).addClass('active');
    }).on('mouseleave', function() {
        $(this).removeClass('active');
    });


    // Specific for page blog list
    if($('body.blog.index').length) {
        $('.blog-categories-toggle').click(function () {
            $(this).toggleClass('hover');
            $('.blog-categories-menu-container').animate({
                height: 'toggle',
                opacity: 'toggle'
            });
        });
    }


    // Links with confirmation
    $('body').on('click', 'a.with-confirmation', function () {
        let alertMsg = $(this).data('alert-msg') || 'Êtes-vous sûr ?'
        return confirm(alertMsg);
    });

    // if ("share" in navigator) {
    //     $('ul.share-links-list').hide();
    //     $('.navigator-share').addClass('visible');
    //     $('.share-links .share-on').hide();
    //     $('.share-links .share-short').removeClass('hidden');
    //
    //     $('.navigator-share').on('click', function(e){
    //         e.preventDefault();
    //
    //         navigator.share({
    //             title: $(this).data('title'),
    //             text: $(this).data('text'),
    //             url: $(this).data('url') ?? window.location.href,
    //         });
    //     })
    // }
});