require('../../node_modules/slick-carousel/slick/slick.min');

let mobileBreakPoint = 767;
let tabletBreakPoint = 1023;
let desktopBreakPoint = 1199;
let desktopLargeBreakPoint = 1599;

function handleSliderInit() {
    setTimeout(function(){
        if (window.stickySidebar) {
            window.stickySidebar.updateSticky();
        }
    }, 500);
}

function initDash(slick) {
    let slideNb = slick.slideCount;
    let dots = slick.$dots;
    let dotsLine = dots.parent();
    let dotsLineWidth = dotsLine.width();
    let dash = dotsLine.find('.active-dash').first();
    let dashWidth = dotsLineWidth / slideNb;
    dash.css({width: dashWidth});
    positionDash(slick, 0);
}

function positionDash(slick, nextSlide) {
    let dots = slick.$dots;
    let dotsLine = dots.parent();
    let dash = dotsLine.find('.active-dash').first();
    let dashWidth = dash.width();
    let dashLeftPosition = nextSlide * dashWidth;
    dash.css({left: dashLeftPosition});
}

$(function () {
    // Example slider with dash
    $('.example-items-list.slider').each(function() {
        let sliderId = '#'+$(this).attr('id');
        let nbSlides = $(sliderId).children().length;
        if (nbSlides >= 1) {

            let mobileSettings = 'unslick';
            let tabletSettings = 'unslick';
            let desktopSettings = 'unslick';
            if (nbSlides > 2) {
                mobileSettings = {
                    slidesToScroll: 2,
                    slidesToShow: 2
                };
                if (nbSlides > 3) {
                    tabletSettings = {
                        slidesToScroll: 3,
                        slidesToShow: 3
                    };
                    if (nbSlides > 4) {
                        desktopSettings = {
                            slidesToScroll: 4,
                            slidesToShow: 4
                        };
                    }
                }
            }

            $(sliderId).on('init breakpoint', function(event, slick) {
                initDash(slick);
                handleSliderInit();
            }).slick({
                dots: true,
                autoplay: false,
                infinite: false,
                slidesToScroll: 1,
                slidesToShow: 1,
                mobileFirst: true,
                variableWidth: false,
                adaptiveHeight: false,
                appendDots: $(sliderId+'-dots'),
                appendArrows: $(sliderId+'-controls'),
                prevArrow: sliderId+'-controls .slick-prev',
                nextArrow: sliderId+'-controls .slick-next',
                responsive: [
                    {
                        breakpoint: mobileBreakPoint,
                        settings: mobileSettings
                    },
                    {
                        breakpoint: tabletBreakPoint,
                        settings: tabletSettings
                    },
                    {
                        breakpoint: desktopBreakPoint,
                        settings: desktopSettings
                    }
                ]
            }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                positionDash(slick, nextSlide);
            });
        }
    });

    // Content Blocks slider
    $('.content-block.list-content.slider ul.items').each(function (i, e) {
        let slider = $(e);

        let sliderId = slider.attr('id');
        let nbSlides = parseInt(slider.children().length);
        let nbCols = parseInt(slider.data('columns'));
        slider.on('init breakpoint', function() {
            handleSliderInit();
        }).slick({
            slide: 'li.item',
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            speed: 600,
            autoplaySpeed: 1000,
            mobileFirst: true,
            cssEase: 'ease',
            appendArrows: $('#' + sliderId + '-controls'),
            prevArrow: '#' + sliderId + '-controls .slick-prev',
            nextArrow: '#' + sliderId + '-controls .slick-next',
            responsive: [
                {
                    breakpoint: mobileBreakPoint,
                    settings: {
                        slidesToShow: Math.min(2, nbSlides),
                        slidesToScroll: nbSlides > 2 ? 1 : nbSlides,
                    }
                },
                {
                    breakpoint: tabletBreakPoint,
                    settings: {
                        slidesToShow: Math.min(nbCols, nbSlides),
                        slidesToScroll: nbSlides > nbCols ? 1 : nbSlides,
                    }
                }
            ]
        });
    });
});