window.$ = window.jQuery = require('jquery');
let Dropzone = require('dropzone');

// require('./jquery.nice-select');
require('select2');
require('select2/dist/js/i18n/fr');
require('../../public/bundles/tetranzselect2entity/js/select2entity');
// SlimSelect = require('slim-select');

require('../../node_modules/jquery-ui/ui/widgets/datepicker');
require('../../node_modules/jquery-ui/ui/i18n/datepicker-fr'); // Add other locales if necessary
require('../../node_modules/jquery-clockpicker/dist/jquery-clockpicker');

$(function () {

    // Ajax forms
    $(document).on('submit', 'form.ajax-form', function(){
        let form = $(this);

        clearAjaxFormError(form);

        form.find('button').attr('disabled', true);

        let formData = new FormData(),
            processData = false,
            contentType = false;

        let values = form.serializeArray();
        for (let i = 0; i < values.length; i++) {
            formData.append(values[i].name, values[i].value);
        }

        form.find('input[type="file"]').each(function () {
            for (let i = 0; i < $(this)[0].files.length; i++) {
                formData.append($(this).attr('name'), $(this)[0].files[i]);
            }
        });

        $.ajax({
            method: 'POST',
            url: form.attr('action'),
            data: formData,
            processData: processData,
            contentType: contentType,
            success: function(response) {
                handleAjaxFormResponse(form, response);
            },
            error: function() {
                handleAjaxFormErrors(form, []);
            },
            complete: function() {
                form.find('button').removeAttr('disabled');
                if (window.stickySidebar) {
                    window.stickySidebar.updateSticky();
                }
            }
        });

        return false;
    });


    // Media fields
    $('.media-field').each(function(i, e) {
        InitMediaField($(e));
    });


    // Nice select
    // $('select.nice-select').each(function() {
    //     InitNiceSelectField($(this));
    // });
    // $(document).on('change', 'select.nice-select', function() {
    //     SetNiceSelectPlaceholder($(this));
    // });
    
    // Select2
    $('select.select2').each(function() {
        InitSelect2Field($(this));
    });
    $('.select2entity[data-autostart="true"]').each(function() {
        SetSelect2EntityPlaceholder($(this));
    });
    $(document).on('change', 'select.select2', function() {
        SetSelect2Placeholder($(this));
    });
    $(document).on('change', 'select.select2entity', function() {
        SetSelect2EntityPlaceholder($(this));
    });
    $(document).on('select2:open', 'select.select2entity', function(e) {
        const selectId = e.target.id
        $('.select2-search__field[aria-controls="select2-' + selectId + '-results"]').each(function ( key, value ) {
            value.focus()
        });
    });
    $(window).on('resize', function() {
        $('select.select2').each(function() {
            FixSelect2Width($(this));
        });
        $('select.select2entity').each(function() {
            FixSelect2EntityWidth($(this));
        });
    });
    
    // Slim select
    // $('select.slim-select').each(function() {
    //     InitSlimSelectField($(this));
    // });
    // $(document).on('change', 'select.slim-select', function() {
    //     SetSlimSelectPlaceholder($(this));
    // });


    // Radio buttons
    $(document).on('change', '.radio input', function () {
        let name = $(this).attr('name');
        $('input[type="radio"][name="' + name + '"]').closest('.radio').removeClass('checked').find('i').removeClass('icon-radio-checked').addClass('icon-radio');
        if ($(this).is(':checked')) {
            $(this).closest('.radio').addClass('checked').find('i').removeClass('icon-radio').addClass('icon-radio-checked');
        }
    });


    // Checkboxes
    $(document).on('change', '.checkbox input', function() {
        if($(this).is(':checked')) {
            $(this).closest('.checkbox').addClass('checked').find('i').removeClass('icon-checkbox').addClass('icon-checkbox-checked');
        }
        else {
            $(this).closest('.checkbox').removeClass('checked').find('i').removeClass('icon-checkbox-checked').addClass('icon-checkbox');
        }
    });


    // Floating labels
    $('.select-container').each(function() {
        // let selectField = el.find('select').first();
        InitSelectField($(this));
    }).on('change', 'select.form-control', function() {
        InitSelectField($(this).parent());
    });


    // Dropdown buttons
    $(document).on('click', '.btn.dropdown-toggle', function() {
        let dropdownMenu = $(this).parent().find('.dropdown-menu');
        dropdownMenu.toggleClass('visible')
    });
    $(document).on('click', '.dropdown-item', function() {
        let dropdownMenu = $(this).parentsUntil('.dropdown-menu').parent();
        dropdownMenu.removeClass('visible')
    });
    $(document).mouseup(function(e) {
        let dropdownMenu = $('.dropdown-menu');
        let dropdownButton = $('.btn.dropdown-toggle');
        if (!dropdownMenu.is(e.target) && dropdownMenu.has(e.target).length === 0 && !dropdownButton.is(e.target) && dropdownButton.has(e.target).length === 0)
        {
            dropdownMenu.removeClass('visible');
        }
    });

    // Datepicker
    window.htmlLang = $('html').attr('lang');
    $.datepicker.setDefaults($.datepicker.regional[htmlLang]);
    $('.datepicker').each(function(i, e) {
        InitDatePicker($(e));
    });

    // Clockpicker
    $('.clockpicker').each(function(i, e) {
        InitClockPicker($(e));
    });



    $('.city-search-form').on('submit', function(e) {
        e.preventDefault();
    });

    // City autocomplete
    $('.search-city-autocomplete').each(function() {
        InitCityAutocomplete($(this));
    });
});

window.clearAjaxFormError = function(form, success = false) {
    form.find('.form-group.has-error').parent().find('.help-block').remove();
    form.find('.form-group.has-error').removeClass('has-error');
    form.find('.ajax-message.error').html('').removeClass('error').addClass('hidden').attr('aria-hidden', true);

    if (success) {
        form.find('.hide-on-success').hide().attr('aria-hidden', true);
        form.find('.show-on-success').show().removeClass('hidden').attr('aria-hidden', false);
    }
};

window.handleAjaxFormErrors = function(form, errors) {
    if (errors.global) {
        for (let key in errors.global) {
            let message = errors.global[key];
            let messageHtml = '<div class="help-block"><ul class="list-unstyled"><li>' + message + '</li></ul></div>';
            form.find('.ajax-message').append(messageHtml).addClass('error').removeClass('hidden');
        }
    }
    for (let key in errors.fields) {
        let message = errors.fields[key],
            input = form.find("#" + key);
        let messageHtml = '<div class="help-block"><ul class="list-unstyled"><li>' + message + '</li></ul></div>';
        let formGroup = input.closest('.form-group');
        formGroup.addClass('has-error');
        // if (formGroup.parent().hasClass('col') || formGroup.parent().hasClass('full')) {
        //     input.parent().after(messageHtml);
        // }
        // else {
            formGroup.append(messageHtml);
        // }
    }
    form.find('.hide-on-error').hide().attr('aria-hidden', true);
    form.find('.show-on-error').show().removeClass('hidden').attr('aria-hidden', false);
};

window.handleAjaxFormResponse = function(form, response) {
    if (response.message) {
        form.find('.ajax-message').removeClass('success').removeClass('error').html(response.message).addClass(response.status).removeClass('hidden').attr('aria-hidden', false);
    }
    if (response.status === 'success') {
        clearAjaxFormError(form, true);

        handleAjaxFormTracking(form, response);
    }
    if (response.status === 'error') {
        handleAjaxFormErrors(form, response.errors || []);
    }
    if (response.scrollTo) {
        smoothScrollTo(response.scrollTo, 30, true);
    }
};

window.handleAjaxFormTracking = function(form, response) {
    // TODO: checker code en prod!
    let eventName = 'form_submit';
    let eventCode = '';
    if (response.tracking && response.tracking.eventName) {
        eventName = response.tracking.eventName;
    }
    else if (form.data('tracking-event-name')) {
        eventName = form.data('tracking-event-name');
    }
    if (response.tracking && response.tracking.eventCode) {
        eventCode = response.tracking.eventCode;
    }
    else if (form.data('tracking-event-code')) {
        eventCode = form.data('tracking-event-code');
    }

    let trackingData = {
        'event': eventName,
        'code': eventCode,
    };

    if (form.data('tracking-custom-vars')) {
        trackingData = {...trackingData, ...form.data('tracking-custom-vars')}
    }

    console.log('Tracking Data: ' + JSON.stringify(trackingData))

    // Tracking GTM
    if (typeof dataLayer !== 'undefined') {
        dataLayer.push(trackingData);
    }

    // Tracking MTM
    if (typeof _mtm !== 'undefined') {
        _mtm.push(trackingData);
    }
};

function InitMediaField(field) {
    // TODO : faire mieux !
    let dropzone;
    let arr = [];
    try {
        dropzone = Dropzone.forElement(field.find("> .manual-dropzone").get(0));
    }
    catch (e) {

    }

    if (!dropzone && field.length) {
        let resizeSize = null;
        if (field.data('acceptedFiles') !== '.gif' || field.data('type') !== 'public') {
            resizeSize = 3000;
        }
        field.find(' > .user-dropzone').dropzone({
            url: Routing.generate('_uploader_upload_media'),
            maxFiles: field.data('maxFiles') || null,
            maxFilesize: 20, // MB
            resizeHeight: resizeSize,
            resizeWidth: resizeSize,
            acceptedFiles: field.data('acceptedFiles') || null,
            clickable: field.find('.user-dropzone, .dz-placeholder, .preview, .dz-preview, .dz-add-files').get(),
            params: {type: field.data('type') || 'public'},
            addRemoveLinks: true,
            dictDefaultMessage: 'Déposez les fichiers ici',
            dictRemoveFile: 'Supprimer',
            dictCancelUpload: 'Annuler',
            dictUploadCanceled: 'Annulé',
            dictCancelUploadConfirmation: 'Êtes-vous sûr ?',
            dictInvalidFileType: 'Type de fichier non accepté',
            dictMaxFilesExceeded: 'Nombre de fichiers max atteint',
            dictFileTooBig: 'Fichier trop volumineux ({{filesize}}MB). Taille max acceptée : {{maxFilesize}}MB.',
            processing: function () {
                $(this.element).find('> .dz-loader').addClass('visible');
            },
            success: function (file, response) {
                if (field.data('maxFiles') !== 1) {

                    $(file).each(function () {
                        arr.push({"id": response.id});
                    });

                    if (arr.length > 0) {
                        field.find('.dz-add-files').addClass('visible');
                    }
                    else {
                        field.find('.dz-add-files').removeClass('visible');
                    }
                    field.find('input[type=hidden]').val(JSON.stringify(arr));
                }
                else {
                    field.find('input[type=hidden]').val(response.id);
                    field.trigger('picture.change', [response.id]);
                }

                if (response.media_preview) {
                    if (response.media_preview === 'icon') {
                        $(file.previewElement).find('.dz-image img').hide();
                        $(file.previewElement).find('.dz-image').append('<i aria-hidden="true" class="fa '+response.icon+'"></i>');
                    }
                    else {
                        $(file.previewElement).find('.dz-image img').attr('src', response.media_preview);
                    }
                }

                $(this.element).find('> .dz-loader').removeClass('visible');
            },
            removedfile: function (file) {
                file.previewElement.remove();
                field.find('.user-dropzone').removeClass('dz-max-files-reached');
                let response = JSON.parse(file.xhr.response);
                let removedFileId = response.id;
                arr = arr.filter(function(value, index, array) {
                    return value.id !== removedFileId;
                });

                if (arr.length > 0) {
                    field.find('.dz-add-files').addClass('visible');
                }
                else {
                    field.find('.dz-add-files').removeClass('visible');
                }

                field.find('input[type=hidden]').val(JSON.stringify(arr));

                $(this.element).find('> .dz-placeholder').addClass('visible');
            }
        });
    }
}


window.InitSelectField = function(el) {
    if ($(el).find('select.form-control').first().val() !== '') {
        $(el).addClass('not-empty');
    }
    else {
        $(el).removeClass('not-empty');
    }
};

// Nice select

// window.InitNiceSelectField = function(field) {
//     let label = field.data('label');
//     field.children('option[value=""]').attr('data-display', label);
//     field.niceSelect();
//     SetNiceSelectPlaceholder(field);
// };

// window.SetNiceSelectPlaceholder = function(field) {
//     if (field.val() === '') {
//         field.parent().find('div.nice-select').removeClass('not-empty');
//         field.parent().find('div.nice-select > .current').addClass('placeholder');
//     }
//     else {
//         field.parent().find('div.nice-select').addClass('not-empty');
//         field.parent().find('div.nice-select > .current').removeClass('placeholder');
//     }
// };


// Select2

window.InitSelect2Field = function(field) {
    field.select2({
        // dropdownAutoWidth: true,
        theme: field.data('select2-theme') ? field.data('select2-theme') : 'default',
        // placeholder: {
        //     id: '-1', // the value of the option
        //     text: field.data('placeholder')
        // }
    });
    SetSelect2Placeholder(field);
};

window.SetSelect2Placeholder = function(field) {
    if (field.val() === '' || field.val().length === 0) {
        field.parent().find('.select2').removeClass('not-empty');
    }
    else {
        field.parent().find('.select2').addClass('not-empty');
    }
};

window.SetSelect2EntityPlaceholder = function(field) {
    if (!field.val() || field.val() === '' || field.val().length === 0) {
        field.parent().find('.select2entity').removeClass('not-empty');
        field.parent().find('.select2').removeClass('not-empty');
    }
    else {
        field.parent().find('.select2entity').addClass('not-empty');
        field.parent().find('.select2').addClass('not-empty');
    }
};

window.FixSelect2Width = function(field) {
    let formGroup = field.closest('.form-group');
    field.parent().find('span.select2').width(0).width(formGroup.width());
};

window.FixSelect2EntityWidth = function(field) {
    let formGroup = field.closest('.form-group');
    field.parent().find('span.select2entity').width(0).width(formGroup.width());
};


// Slim select

// function InitSlimSelectField(field) {
//     new SlimSelect({
//         select: '#'+field.attr('id'),
//         showContent: 'auto',
//         searchText: field.data('search-noresult'),
//         searchPlaceholder: field.data('search-placeholder'),
//     });
//     SetSlimSelectPlaceholder(field);
// }

// function SetSlimSelectPlaceholder(field) {
//     if (field.val() === '') {
//         field.parent().find('div.slim-select').removeClass('not-empty');
//         field.parent().find('div.slim-select > .ss-single-selected > .placeholder').removeClass('selected');
//     }
//     else {
//         field.parent().find('div.slim-select').addClass('not-empty');
//         field.parent().find('div.slim-select > .ss-single-selected > .placeholder').addClass('placeholder');
//     }
// }

window.InitDatePicker = function(field) {
    if (field.length) {
        let dateFormat = 'yy-mm-dd';
        let options = {
            // dateFormat: dateFormat,
            todayBtn: 'linked',
            gotoCurrent: true,
            keyboardNavigation: false,
            // forceParse: false,
            calendarWeeks: true,
            autoclose: true,
            // changeMonth: true,
            changeYear: true,
            // yearRange: '2002:2012',
            yearRange: 'c-100:c',
            prevText: '',
            nextText: '',
            autosize: true,
        }
        let direction = field.data('direction') ? field.data('direction') : 'auto';
        if (direction !== 'auto') {
            options.showOptions = {direction: direction};
        }
        if (field.data('datepicker-max-date')) {
            options.maxDate = $.datepicker.parseDate(dateFormat, field.data('datepicker-max-date'));
        }

        let availableWeekDays = field.data('datepicker-available-weekdays') ? field.data('datepicker-available-weekdays') : [];
        let forbiddenDates = field.data('datepicker-forbidden-dates') ? field.data('datepicker-forbidden-dates') : [];
        let holidays = field.data('datepicker-holidays') ? field.data('datepicker-holidays') : [];
        options.beforeShowDay = function(date) {

            if (availableWeekDays.length && availableWeekDays.indexOf(date.getDay()) === -1) {
                return [false];
            }

            let dateString = $.datepicker.formatDate(dateFormat, date);

            if (forbiddenDates.length && forbiddenDates.indexOf(dateString) !== -1) {
                return [false];
            }

            let inHolidays = false;
            holidays.forEach(function (holiday) {
                if (dateString >= holiday.start && dateString <= holiday.end) {
                    inHolidays = true;
                }
            });

            return [!inHolidays];
        };

        field.datepicker(options);
    }
}

window.InitClockPicker = function(field) {
    if(field.length) {
        field.jqclockpicker({
            autoclose: true,
        });
    }
}



// Init City Autocomplete
window.InitCityAutocomplete = function(field) {
    field.autocomplete({
        autoFocus: true,
        minLength: 1,
        // change: function( event, ui ) {
        //     $(this).autocomplete('search', $(this).val());
        // },
        select: function (event, ui) {
            $(this).val(ui.item.value);

            let trackingData = {
                'event': 'search_ville',
                'id_ville': ui.item.city_id + ' - ' + ui.item.text,
            };
            console.log('Tracking Data: ' + JSON.stringify(trackingData))
            // Tracking MTM
            if (typeof _mtm !== 'undefined') {
                _mtm.push(trackingData);
            }

            if ($(event.target).length && $(event.target).data('remoteParams') && $(event.target).data('remoteParams').follow) {

                window.location.href = ui.item.id;
            }
        },
        position: {
            my: 'right top',
            at: 'right bottom+10'
        },
        open: function (event, ui) {
            $(this).addClass('with-drop-down')
        },
        close: function (event, ui) {
            $(this).removeClass('with-drop-down')
        },
        source: function (request, response) {
            let routeParams = {'follow': true};
            if (this.element.data('remoteParams')) {
                routeParams = this.element.data('remoteParams');
            }
            $.ajax({
                url: Routing.generate('config_autocomplete_city', routeParams),
                dataType: 'json',
                data: {
                    q: request.term,
                },
                success: function (data) {
                    response(data);
                }
            });
        },
    }).data('ui-autocomplete')._renderItem = function (ul, item) {
        return $('<li></li>')
            .data('item.autocomplete', item)
            .append(item.html)
            .appendTo(ul);
    };
}