let $ = require('jquery');

import { Rgpd } from './base.rgpd';

let youtubePlayer, vimeoPlayer;

let youtubeApiIsReady = false;
let youtubeRequestedVideo = null;

let vimeoPlayerLoaded = false;
let vimeoRequestedVideo = null;

window.onYouTubeIframeAPIReady = function(){
    youtubeApiIsReady = true;

    if(youtubeRequestedVideo) {

        youtubePlayer = new YT.Player(youtubeRequestedVideo.playerId, {
            height: '100%',
            width: '100%',
            videoId: youtubeRequestedVideo.videoId,
            playerVars: {
                rel: 0,
                showinfo: 0
            },
            events: {
                'onReady': onPlayerReady
            }
        });

        if (youtubeRequestedVideo.fullScreen) {
            $('#video-popup').fadeIn();
        } else {
            $('#' + youtubeRequestedVideo.playerId).fadeIn();
        }
    }
}

function onVimeoPlayerLoaded() {

    vimeoPlayerLoaded = true;
    if(vimeoRequestedVideo) {

        let options = {
            id: vimeoRequestedVideo.videoId,
            width: '100%',
            height: '100%',
            loop: true,
            autoplay: true
        };

        vimeoPlayer = new Vimeo.Player(vimeoRequestedVideo.playerId, options);

        if (vimeoRequestedVideo.fullScreen) {
            $('#video-popup').fadeIn();
        }
        else {
            $('#' + vimeoRequestedVideo.playerId).fadeIn();
        }
    }
}

function init() {
    $('body').on('click', '.popup-close', function () {
        $(this).parent('div').fadeOut(300);

        // TODO : a faire autrement
        if (youtubePlayer) {
            youtubePlayer.destroy();
        }
        if (vimeoPlayer) {
            vimeoPlayer.pause();
        }

        // InitPopupPlayer();
    });

    $('body').on('click', '.video-button a.button-video', function (e) {

        let videoBtn = $(this);
        let videoRgpdAdvert = videoBtn.next('div.video-rgpd-advert');
        let videoUrl = $(this).data('video-url');
        let target = $(this).data('target');

        if (target !== 'full-screen') {
            videoBtn.addClass('hidden');
        }

        if (videoUrl && target) {

            // todo: gerer detection youtube propre
            let rgpdService = '';
            if (videoUrl.match(/vim/gi)) {
                rgpdService = 'vimeo';
            } else {
                rgpdService = 'youtube';
            }

            Rgpd.test('video.'+rgpdService, () => {
                openVideo(videoUrl, target);
            }, () => {
                videoBtn.addClass('hidden');
                videoRgpdAdvert.removeClass('hidden');
            });
        }
        return e.preventDefault();
    });
}

function InitPopupPlayer() {
    $('#video-popup').remove();
    let videoPopup = '' +
        '<div id="video-popup">' +
        '<div class="popup-close">' +
        '<span class="burger-div"></span>' +
        '</div>' +
        '<div class="video-popup-container">' +
        '<div class="player" id="video-popup-player"></div>' +
        '</div>' +
        '</div>';
    $(videoPopup).appendTo('body');
}

function onPlayerReady(event) {
    event.target.playVideo();
}

function openVideo(videoUrl, target) {

    let playerId, fullScreen;

    if (target === 'full-screen') {
        fullScreen = true;
        playerId = 'video-popup-player';
    } else {
        fullScreen = false;
        playerId = 'video-player-' + target;
    }

    if (fullScreen) {
        InitPopupPlayer();
    }

    if (videoUrl.match(/vim/gi)) {

        if(vimeoPlayerLoaded) {
            let options = {
                id: vimeo_parser(videoUrl),
                width: '100%',
                height: '100%',
                loop: true,
                autoplay: true
            };

            vimeoPlayer = new Vimeo.Player(playerId, options);

            if (fullScreen) {
                $('#video-popup').fadeIn();
            }
            else {
                $('#' + playerId).fadeIn();
            }
        } else {
            let scriptTag = document.createElement( "script" );

            scriptTag.onload = function() {
                onVimeoPlayerLoaded();
            }

            scriptTag.id = 'vimeo-script';
            scriptTag.src = 'https://player.vimeo.com/api/player.js';

            document.getElementsByTagName( "head" )[0].appendChild( scriptTag );

            vimeoRequestedVideo = {
                playerId: playerId,
                fullScreen: fullScreen,
                videoId: vimeo_parser(videoUrl),
            };

        }
    }
    else {
        if(youtubeApiIsReady) {

            new YT.Player(playerId, {
                height: '100%',
                width: '100%',
                videoId: youtube_parser(videoUrl),
                playerVars: {
                    rel: 0,
                    showinfo: 0
                },
                events: {
                    'onReady': onPlayerReady
                }
            });

            if (fullScreen) {
                $('#video-popup').fadeIn();
            }
            else {
                $('#' + playerId).fadeIn();
            }
        } else {

            if (!document.getElementById('youtube-script')) {
                let scriptTag = document.createElement("script");
                scriptTag.id = 'youtube-script';
                scriptTag.src = 'https://www.youtube.com/iframe_api';

                document.getElementsByTagName("head")[0].appendChild(scriptTag);
            }

            youtubeRequestedVideo = {
                playerId: playerId,
                fullScreen: fullScreen,
                videoId: youtube_parser(videoUrl),
            };
        }
    }

}

function youtube_parser(url) {
    let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    let match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
}

function vimeo_parser(url) {
    let regExp = /http(s)?:\/\/(www\.)?vimeo.com\/(\d+)(\/)?(#.*)?/;
    let match = url.match(regExp);
    if (match)
        return match[3]
}


export { init };
